<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="setEdit(false)"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges()"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <h6 class="mt-4">
      Dados do financeiro
    </h6>
    <!-- <validation-observer ref="form">
      <b-row>
        <b-col
          class="mt-2"
          md="7"
        >
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required|max:50|alpha_spaces"
              >
                <b-form-group label="Nome">
                  <b-input-group class="input-group-merge disabled">
                    <b-form-input
                      id="nome"
                      v-model="form.name"
                      placeholder="Nome"
                      type="text"
                      :disabled="!edit"
                    />
                    <b-input-group-append
                      v-if="!edit"
                      v-b-popover="{ variant: 'dark', content: 'Este campo está desabilitado para alterações.' }"
                      is-text
                      class="cursor-pointer"
                    >
                      <VSIcons
                        v-if="!edit"
                        name="InfoCircle"
                        color="#A0AEB9"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="pl-0">
              <validation-provider
                v-slot="{ errors }"
                name="e-mail"
                rules="required|email"
              >
                <b-form-group label="E-mail">
                  <b-input-group class="input-group-merge disabled">
                    <b-form-input
                      id="email-financial"
                      v-model="form.email"
                      placeholder="Email"
                      type="email"
                      :disabled="!edit"
                    />
                    <b-input-group-append
                      v-if="!edit"
                      v-b-popover="{ variant: 'dark', content: 'Este campo está desabilitado para alterações.' }"
                      is-text
                      class="cursor-pointer"
                    >
                      <VSIcons
                        v-if="!edit"
                        name="InfoCircle"
                        color="#A0AEB9"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="pl-0">
              <validation-provider
                v-slot="{ errors }"
                name="telefone"
                rules="required|phone"
              >
                <b-form-group label="Telefone">
                  <b-input-group class="input-group-merge disabled">
                    <b-form-input
                      id="telefone"
                      v-model="form.telephone"
                      v-mask="maskTelephone(form.telephone)"
                      placeholder="Telefone"
                      type="text"
                      :disabled="!edit"
                    />
                    <b-input-group-append
                      v-if="!edit"
                      v-b-popover="{ variant: 'dark', content: 'Este campo está desabilitado para alterações.' }"
                      is-text
                      class="cursor-pointer"
                    >
                      <VSIcons
                        name="InfoCircle"
                        color="#A0AEB9"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer> -->
    <b-table
      id="waste-table"
      ref="wasteTable"
      class="details-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/waste/general', 'Waste')"
        />
      </template>
      <template
        v-if="$can('delete', 'waste')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/waste/general', fetchTable: 'Waste' })"
        />
      </template>
      <template #cell(issue_date)="row">
        {{ row.item.issue_date | formatLocalDate }}
      </template>
      <template #cell(due_date)="row">
        {{ row.item.due_date | formatLocalDate }}
      </template>
      <template #cell(payment_amount)="row">
        R${{ row.item.payment_amount | formatPrice }}
      </template>
      <template #cell(plan_recurrence)="row">
        {{ row.item.account.plan_recurrence }}
      </template>
      <template #cell(payment_date)="row">
        <div
          v-if="row.item.payment_date"
          class="d-flex align-items-center justify-content-center"
        >
          <span class="validade">{{ row.item.payment_date | formatLocalDate }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
        <span v-else>{{ 'Não consta' }}</span>
      </template>
      <template #cell(actions)="row">
        <b-dropdown
          no-caret
          right
          variant="light"
          menu-class="dropdown-table-actions"
          toggle-class="button-dropdown-actions"
        >
          <template #button-content>
            <VSIcons
              name="Actions"
              width="5"
              height="22"
            />
          </template>
          <b-dropdown-item
            class="dropdown-table-item"
            @click="openBoleto(row.item.invoice_link)"
          >
            <VSIcons
              name="DocumentText"
              width="20"
              height="20"
              color="#7D8E9E"
            />
            <span>
              Segunda via
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BButton, VBPopover, BTable, BDropdown, BDropdownItem, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { required } from '@core/utils/validations/validations'
import { maskPhone, tableConfig } from '@core/mixins/ui/forms'
import { formatLocalDate, formatPrice } from '@core/utils/filter'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  directives: {
    'b-popover': VBPopover,
  },
  components: {
    BButton,
    // BRow,
    // BCol,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormCheckbox,
    VSIcons,
  },
  filters: {
    formatLocalDate,
    formatPrice,
  },
  mixins: [
    maskPhone,
    tableConfig,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
    idAccount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      form: {
        name: '',
        email: '',
        telephone: '',
      },
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Emissão',
          key: 'issue_date',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Data de Vencimento',
          key: 'due_date',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Valor',
          key: 'payment_amount',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Recorrência',
          key: 'plan_recurrence',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Forma de Pag.',
          key: 'payment_method',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Situação',
          key: 'status',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: 'Data Pag.',
          key: 'payment_date',
          sortable: true,
          tdClass: 'align-middle',
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'align-middle',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ edit: 'app/getItemTableEdit', apiInCall: 'app/getApiInCall' }),
  },
  created() {},
  methods: {
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT' }),
    openBoleto(link) {
      window.open(link)
    },
  },
}
</script>

<style>

</style>
