<script> /* eslint-disable */</script>
<template>
  <div>
    <div class="btn-actions-content d-flex">
      <b-button
        variant="outline-danger"
        class="d-flex align-items-center w-100 mr-3 cancel-plan"
        @click="showModal('CancelPlan')"
      >
        <VSIcons
          name="CloseSquare"
          color="#FD3258"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="text-nowrap">Solicitar Cancelamento</span>
      </b-button>
      <!-- <b-button
        v-if="userAccount != 'Master'"
        href="/assinar-plano"
        class="btn-grad d-flex align-items-center w-100"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="text-nowrap">{{ buttonName }}</span>
        
      </b-button> -->
    </div>
    <div class="container-fluid">
      <h6 class="mt-4 mb-4">
        Detalhes do plano
      </h6>
      <b-row>
        <b-col
          class="d-flex justify-content-between align-items-center plan-card-wrapper rounded border-0 shadow-pegasus bg-white p-3"
          md="12"
        >
          <b-card class="border-0">
            <div>
              <b-badge class="p-2 bg-grandient-color">
                <span class="plan-type-info font-11 font-weight-normal text-uppercase p-2">
                  {{ plan.planName || '' }}
                </span>
              </b-badge>
              <span class="text-dark font-weight-bold font-13 ml-2">{{ plan.planPrice }}</span>
            </div>
            <ul class="list-unstyled">
              <li
                v-if="plan.bonusUser"
                class="d-flex align-items-center plan-item"
              >
                <VSIcons
                  class="correct-sign"
                  name="correct-icon-fill"
                  height="16"
                /><strong>{{ plan.bonusUser }}</strong>
              </li>
              <li
                v-if="plan.bonusProject"
                class="d-flex align-items-center plan-item"
              >
                <VSIcons
                  class="correct-sign"
                  name="correct-icon-fill"
                  height="16"
                /><strong>{{ plan.bonusProject }}</strong>
              </li>

            </ul>
          </b-card>
          <div class="d-flex flex-column text-right price-details">
            <span> Valor Total: </span>
            <span> R$ {{ plan.totalPrice }} </span>
            <span> Inscluso os serviços adicionais* </span>
          </div>
        </b-col>
        <b-col
          class="pl-0"
          md="3"
        />

        <b-col
          class="pl-0"
          md="3"
        />
      </b-row>
      <b-row>
        <b-card class="rounded border-0 shadow-pegasus">
          <span class="d-flex font-weight-bold font-13">
            <VSIcons
              name="User"
              width="22"
              height="22"
              color="black"
              class="pr-2"
            />
            {{ plan.freeUsersMsg }}
          </span>
          <b-progress
            :value="plan.maxUserLimit - plan.freeUsers"
            :max="plan.maxUserLimit"
            class="mb-1 mt-2"
          />
          <span class="text-secondary font-13"> {{ plan.activeUsers }} usuários usados de <strong>{{ plan.maxUserLimit }} usuários</strong>. </span>
        </b-card>
        <b-card class="rounded border-0 shadow-pegasus ml-5">
          <span class="d-flex font-weight-bold font-13">
            <VSIcons
              name="Briefcase"
              width="20"
              height="20"
              color="black"
              class="pr-2"
            />
            {{ plan.freeProjectsMsg }}
          </span>
          <b-progress
            :value="plan.maxProjectsLimit - plan.freeProjects"
            :max="plan.maxProjectsLimit"
            class="mb-1 mt-2"
          />
          <span class="text-secondary font-13">{{ plan.maxProjectsLimit - plan.freeProjects }} empreendimentos usados de <b>{{ plan.maxProjectsLimit }} empreendimentos</b>. </span>
        </b-card>
      </b-row>
    </div>
    <VSModal
      width="1070"
      name="ChoosePlan"
      :plan="plan"
    />
    <VSModal
      width="600"
      name="CancelPlan"
    />
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BProgress, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import VSModal from '@/components/modals/VSModal.vue'
import api from '@/libs/axios'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BProgress,
    BBadge,

    VSIcons,
    VSModal,
  },
  props: {
    idAccount: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      userAccount: '',
      buttonName: '',
      isActive: null,
    }
  },
  computed: { ...mapGetters({ plan: 'accounts/getMyPlan', getUserData: 'auth/getUserData', accountsData: 'accounts/getAccountsData' }) },
  mounted() {
    this.checkPermissionPlanActive();
    this.userAccount = JSON.parse(localStorage.getItem('user')).role;
    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => {
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    checkPermissionPlanActive() {
      const buttonName = this.isActive ? 'Mudar de plano' : 'Assinatura de plano'
      this.buttonName = buttonName
    },
  },
}
</script>

<style lang="scss">
.plan-type-info {
  letter-spacing: 1.7px !important;
}
.plan-item {
margin: 12px 0px;
font-size: 13px;
line-height: 100%;
color: #7D8E9E;
}
.plan-card-wrapper {
  margin-bottom: 21px;
}
.bg-white {
  background-color: #fff;
}
.price-details {
  span {
    &:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    color: #7D8E9E;
    }
    &:nth-child(2) {
    font-weight: 600;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: 0.75px;
    color: #33383C;
    margin: 10px 0px;
    }
    &:nth-child(3) {
    font-size: 13px;
    line-height: 100%;
    color: #7D8E9E;
    }
  }
}
.cancel-plan {
  &:hover {
    svg {
      path {
        stroke: #FFF !important;
      }
    }
  }
}
</style>
